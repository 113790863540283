import request from '@/utils/request'


// 用户管理:查询
export function platform_api_user_query(data) {
    return request({
        url: '/platform_api/manage_api/user/query',
        method: 'post',
        data: data
    })
}


//一级:数据大屏查询列表
export function platform_bigdata_user_query(data) {
    return request({
        url: '/platform_api/manage_api/visualization_account/query',
        method: 'post',
        data: data
    })
}

//一级:数据大屏创造列表
export function platform_bigdata_user_create(data) {
    return request({
        url: '/platform_api/manage_api/visualization_account/create',
        method: 'post',
        data: data
    })
}

//一级:数据大屏创造列表
export function platform_bigdata_user_delete(data) {
    return request({
        url: '/platform_api/manage_api/visualization_account/delete',
        method: 'post',
        data: data
    })
}
