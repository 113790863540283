<template>
  <div>

    <div class="commonInputTopBox">

    <pageHeader :pagename="'用户管理'" :total="pageCurrent.total"/>

    <el-form :inline="true" class="demo-form-inline" >

      <el-form-item label="通过手机号或用户名搜索">
        <el-input placeholder="请输入" v-model="data.keyword"></el-input>
      </el-form-item>
      
      <el-form-item label="账户状态">
          <el-select v-model="data.account_status" placeholder="用户状态">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="正常" :value="0"></el-option>
            <el-option label="黑名单" :value="1"></el-option>
            <el-option label="未授权" :value="2"></el-option>
          </el-select>
      </el-form-item>


      <el-form-item style="float: right;">
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>

    </el-form>

    </div>
          <div class="layoutTabaleBorder">
          <el-table :data="pageCurrent.tableData" style="width: 100%">
            
            <el-table-column prop="id" label="系统ID" width="100"> </el-table-column>

            <el-table-column label="用户头像" width="80" align="center">
              <template slot-scope="scope">
                  <el-avatar>
                    <el-image
                      :src="scope.row.avatar_url"
                      fit="covers">
                    </el-image>
                  </el-avatar>
              </template>
            </el-table-column>
            
            <el-table-column prop="nick_name" label="用户名" align="center"> </el-table-column>
            <el-table-column prop="wechat_openid" label="OpenID" align="center"> </el-table-column>
            <el-table-column prop="status" label="用户状态" width="180" fixed="right" align="center"> </el-table-column>
            <el-table-column prop="gender" label="性别" width="80" fixed="right"> </el-table-column>
            <el-table-column prop="car_count" label="已绑定车辆数量" align="center" width="180" fixed="right"> </el-table-column>
            
            <el-table-column label="更多" fixed="right" align="left" width="180">
              <template slot-scope="scope">
                <el-button @click="editAccount(scope.row.id)">查看用户详细信息</el-button>
              </template>
            </el-table-column>

            <!-- <el-table-column label="更多" fixed="right" width="140">
              <template slot-scope="scope">
                <el-button @click="editAccount(scope.row.id)">车辆详情</el-button>
              </template>
            </el-table-column> -->

          </el-table>
          </div>
      
          <div class="layoutBorder">
            <Pagination :total="pageCurrent.total" :page.sync="pageCurrent.currentPage" @pagination="getList()" :limit.sync="pageCurrent.pageSize"/>
          </div>

  </div>
</template>

<script>
// 载入接口
// import { 接口函数 } from "@/api/接口函数定义文件";
// import { personnelAccountQuery } from "@/api/personnel.js"
import { platform_api_user_query } from "@/api/manage/business/usermanage/usermanage.js"

// 载入方法
// import { 方法名 } from "@/utils/js文件名";

// 载入组件
// import 组件名 from "@/components/组件文件.vue";
import Pagination from "@/components/Pagination.vue";

// 请求接口例子
/*
    this.$http(
        authAdminLogin({
            account: this.account,
            password: this.password,
        }
    ),
    (res) => {
        if (res.code == 200) {} else {}
    });
*/

export default {
  name: "start",
  data() {
    return {
      data: {
          
          keyword: null,
          account_status: null,
      },
      variate: {},
      status: {},
      doc:{
          status_dict:{
            1: "正常",
            2: "禁用",
            3: "黑名单"
          }
      },
      pageCurrent: {
        tableData: [],
        pagesize: 10,
        currentPage: 1,
        total: 0,
        pageCount: 0
      },
    };
  },
  components: {
      Pagination
  },
  created() {
      this.getList()
  },
  methods: {
    getList() {
      this.$http(
        platform_api_user_query({
          pagesize: this.pageCurrent.pagesize,
          querypage: this.pageCurrent.currentPage,
          keyword: this.data.keyword,
          account_status:this.data.account_status
        }),
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.pageCurrent.total = res.data.total;
            this.pageCurrent.currentPage = res.data.currentPage;
            this.pageCurrent.pageCount = res.data.pageCount;
            this.pageCurrent.tableData = res.data.result;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
        }
      );
    },
    editAccount(ids) {
        this.$router.push({name:'partnerEditAccount',query: {id:ids}})
    }
  },
};
</script>

<style type="scss">
</style>